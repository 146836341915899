import React, { useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames/bind"
import Img from "gatsby-image"
import SEO from "../../components/seo"

import wordmark_orange from '../../images/brand/wordmark_orange.svg';

import style from "./@laboratories.module.css"
import Anchors from "./anchors.js"
import Identity from "./identity.js"
import Clients from "./clients.js"
import Team from "./team.js"
import Contact from "./contact.js"
import Location from "./location.js"
import Footer from "./footer.js"
// import { Columns, Baseline } from "../../components/visual-aid.js"
// import client_mindlezz from "../../images/client/client-mindlezz.svg"

import {
    flashImage,
    customHookScrollEvent
    // pageSlowReveal,
    // highlightStoryLink,
    // highlightInterface,
    // customScrollEvent,
    // pullContent
} from "../../components/scroll-effects.js"

const cx = classNames.bind(style)

//


export default function Retail() {

    const data = useStaticQuery(
        graphql`
      query {

        #
        laboratory_landscape: file(relativePath: { eq: "work/laboratory/laboratory_landscape.jpg" }) {
          ...defaultImageTreatment
        }
        #
        laboratory_portrait: file(relativePath: { eq: "work/laboratory/laboratory_portrait.jpg" }) {
          ...defaultImageTreatment
        }




        #
        client_hexgon_metrology_01: file(relativePath: { eq: "work/laboratory/client_hexgon_metrology_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_hexgon_metrology_02: file(relativePath: { eq: "work/laboratory/client_hexgon_metrology_02.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_rlrc_01: file(relativePath: { eq: "work/laboratory/client_rlrc_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rlrc_03: file(relativePath: { eq: "work/laboratory/client_rlrc_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
         #
        client_rlrc_04: file(relativePath: { eq: "work/laboratory/client_rlrc_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rlrc_05: file(relativePath: { eq: "work/laboratory/client_rlrc_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
         #
        client_rlrc_06: file(relativePath: { eq: "work/laboratory/client_rlrc_06.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rlrc_07: file(relativePath: { eq: "work/laboratory/client_rlrc_07.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_tuv_01: file(relativePath: { eq: "work/laboratory/client_tuv_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_tuv_02: file(relativePath: { eq: "work/laboratory/client_tuv_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
         #
        client_tuv_03: file(relativePath: { eq: "work/laboratory/client_tuv_03.jpg" }) {
          ...defaultImageTreatment
        }
        #

         #
        client_ndt_lab_01: file(relativePath: { eq: "work/laboratory/client_ndt_lab_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        #
        client_ndt_lab_02: file(relativePath: { eq: "work/laboratory/client_ndt_lab_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_ndt_lab_03: file(relativePath: { eq: "work/laboratory/client_ndt_lab_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
         #
        client_ndt_lab_04: file(relativePath: { eq: "work/laboratory/client_ndt_lab_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_ndt_lab_05: file(relativePath: { eq: "work/laboratory/client_ndt_lab_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
         #
        client_ndt_lab_06: file(relativePath: { eq: "work/laboratory/client_ndt_lab_06.jpg" }) {
          ...defaultImageTreatment
        }
        


      }
    `
    )

    useLayoutEffect(() => {
        // //
        flashImage("#client_hexgon_metrology_01")
        flashImage("#client_hexgon_metrology_02")
        // //
        flashImage("#client_rlrc_01")
        flashImage("#client_rlrc_03")
        flashImage("#client_rlrc_04")
        flashImage("#client_rlrc_05")
        flashImage("#client_rlrc_06")
        flashImage("#client_rlrc_07")
        // //
        flashImage("#client_tuv_01")
        flashImage("#client_tuv_02")
        flashImage("#client_tuv_03")
        //
        //
        //
        customHookScrollEvent("#tagline", "#tagline", "tagline_expanded", .9)
        customHookScrollEvent("#taglineTop", "#taglineTop", "taglineTop_expanded", .5)
    }, [])

    return (
        <>
            {/* <Columns />
            <Baseline /> */}

            <SEO
                title="Spaces For Life"
                keywords={[`UX`, `UX Strategy`, `Bangalore`, `UX Design`, `Consultant`]}
            />
            <div className={style.page}>
                <main className={style.main}>
                    <div className={style.aboveFold}>
                        <div className={style.topLine}></div>
                        <div className={style.topImage}>
                            <div className={cx(style.topImageAsset)}>
                                <div className={style.matte}></div>
                                <Img
                                    className={cx(style.image, style.bannerLandscape)}
                                    fluid={data.laboratory_landscape.childImageSharp.fluid}
                                    alt="laboratory_landscape.jpg"
                                />
                                <Img
                                    className={cx(style.image, style.bannerPortrait)}
                                    fluid={data.laboratory_portrait.childImageSharp.fluid}
                                    alt="laboratory_portrait.jpg"
                                />
                            </div>

                        </div>
                        <div className={style.topInfo}>
                            <img className={style.wordmark} src={wordmark_orange} alt="wordmark.svg" />
                            <div id="taglineTop" className={style.taglineTop}>
                                spaces for growth
                            </div>
                        </div>
                    </div>

                    <div id="about-work" className={style.aboutWork}>
                        <div className={style.header}>Labs</div>
                        <div className={style.aboutWorkText}>
                            Often it’s an interesting question if the design and creativity has any contribution to laboratories. Are laboratories mere operational spaces, which function upon the specified sets of chemical formulas? We bring a discrete impact on laboratories environments through the powerful tool of design. We at SAMYAK feel that an effectively designed laboratory can function in much more better way than the traditional ones. We believe that design can enhance the clinical ambience by adding sanctity to it. The people working in laboratories can be made more productive through the use of proper material, textures, colours and visuals. We help our clients to transform their mundane work culture of labs into fresh and energetic work spaces, adding zeal through our creations.
                        </div>
                    </div>

                    <div id="work" className={style.work}>
                        <div className={style.header}>Work</div>

                        {/* TUV - White ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Clinical whiteness
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        It was our second project in line for TUV SUD. TUV planned to venture into NDT testing, which was a new sector for them. We were given the chance to help push this new business through the new designed environment. The layout comprised of providing 50% area to lab functions while 30% area for new office. Rest 20% was planned as future expansions. It was deliberately asked by client to use white as maximum to make the look as bright as possible.
                                    </p>
                                    <p>
                                        Owning this brief, flooring, modular furniture, walls are maintained white. This white is well accentuated with colourful TUV branding which is done floor to ceiling as various places. The new environment is received with utter enthusiasm by client. Later a successful NABL accreditation was obtained by client for the lab area. Though maintenance is an important task for client here, still the white magic of lab and office has sense of purity, transparency & originality which are most important factors for a Testing & Certification Company.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>TUV SUD South Asia Pvt. Ltd.</div>
                                    <div className={style.projectLocation}>Noida, UP, India.</div>
                                    <div className={style.projectIndustry}>Testing & Certification</div>
                                    <div className={style.projectCompletion}>2018</div>
                                    <div className={style.projectSize}>19500</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_ndt_lab_06" className={cx(style.image)}>
                                        <Img fluid={data.client_ndt_lab_06.childImageSharp.fluid}
                                            alt="client_ndt_lab_06.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_ndt_lab_01" className={cx(style.image)}>
                                        <Img fluid={data.client_ndt_lab_01.childImageSharp.fluid}
                                            alt="client_ndt_lab_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_ndt_lab_02" className={cx(style.image)}>
                                        <Img fluid={data.client_ndt_lab_02.childImageSharp.fluid}
                                            alt="client_ndt_lab_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_ndt_lab_04" className={cx(style.image)}>
                                        <Img fluid={data.client_ndt_lab_04.childImageSharp.fluid}
                                            alt="client_ndt_lab_04.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_ndt_lab_05" className={cx(style.image)}>
                                        <Img fluid={data.client_ndt_lab_05.childImageSharp.fluid}
                                            alt="client_ndt_lab_05.jpg"
                                        />
                                    </div>
                                </div>

                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_ndt_lab_03" className={cx(style.image)}>
                                        <Img fluid={data.client_ndt_lab_03.childImageSharp.fluid}
                                            alt="client_ndt_lab_03.jpg"
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                        {/* TUV - White ****************************** */}

                        {/* Rohailkhand ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Opening the Horizon
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        RLRC is India’s fastest growing Analytical Laboratory engaged in Analytical Testing, Agri-Research and Training Services. RLRC’s specialised services cover distinct segments like Food and Agriculture, Soap and Detergent, Fine Chemicals, Environment and Soil, Essential Oils, Ayurvedic Products, and Packaging Materials etc. Client had aspirations to set up their new Micro-Biology lab of International standards. The new lab was required to meet all certification levels specified by NABL & ILAC. The lab has been created on a small shared floor plate. However, within the given carpet area, the layout came out beautifully accommodating all functions efficiently. The specs selected for metal wall, metal ceiling and epoxy flooring are high end. The final outcome for our client it’s like a big bash in a small pack.
                                    </p>
                                    {/* <p>
                                        The newly built office cum Lab for Hexagon is an important one capturing customers in north India region. Spread over a floor plate of 8500 sqft the office consists of an equipment Lab centre close to reception area, a 20 seater board room, MD cabins, manager’s cabin, executive cubicles, equipment store and a small cafeteria.
                                        The project was a low budget project keeping the required aesthetics and precision of the detailing. Overall, it was a satisfactory completion project which came at par with clients expectations. Client has handed over an appreciation letter soon after successful completion of the project.
                                    </p> */}
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>RLRC (Rohilkhand Laboratory & Research Centre)</div>
                                    <div className={style.projectLocation}>Bareilly, UP, India.</div>
                                    <div className={style.projectIndustry}>Food & Research</div>
                                    <div className={style.projectCompletion}>2020</div>
                                    <div className={style.projectSize}>1500</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_rlrc_01" className={cx(style.image)}>
                                        <Img fluid={data.client_rlrc_01.childImageSharp.fluid}
                                            alt="client_rlrc_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_rlrc_03" className={cx(style.image)}>
                                        <Img fluid={data.client_rlrc_03.childImageSharp.fluid}
                                            alt="client_rlrc_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_rlrc_04" className={cx(style.image)}>
                                        <Img fluid={data.client_rlrc_04.childImageSharp.fluid}
                                            alt="client_rlrc_04.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_rlrc_05" className={cx(style.image)}>
                                        <Img fluid={data.client_rlrc_05.childImageSharp.fluid}
                                            alt="client_rlrc_05.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_rlrc_06" className={cx(style.image)}>
                                        <Img fluid={data.client_rlrc_06.childImageSharp.fluid}
                                            alt="client_rlrc_06.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_rlrc_07" className={cx(style.image)}>
                                        <Img fluid={data.client_rlrc_07.childImageSharp.fluid}
                                            alt="client_rlrc_07.jpg"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Rohailkhand ****************************** */}

                        {/* TUV ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Setting the Milestone
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        TÜV SÜD South Asia is the leading certification, testing, auditing, inspection and training company with a strong presence in India, Bangladesh and Sri Lanka. They have headquarters in Munich, Germany.
                                        Noida office was their one of the important office & Lab to cater the entire north India. The client brief comprised of an office space for sitting of 55 people with a Microbiology & Environment lab. One third of floor plate was dedicated to lab. And office accommodated in rest of two-third area. The budget for the project was said to be limited. It was a Design & Build project for Samyak.
                                        The layout done successfully to accommodate all the requirements. The lab area is designed to have an ultra-modern look that symbolizes company’s international standards in the market. Black granite counters with pure white furniture with TUV SUD brand blue pinches actually enhanced the sophistication of lab in first look.
                                    </p>
                                    <p>
                                        The office area is an open planning with exposed ceiling painted in grey and white. Light wooden shade modular furniture with TUV blue screen fabric. The reception has a straight forward clinical look that client was asking for. Board room is rather a mix of fabric panel, laminated walls, carpet flooring and veneer worktop. The overall clinical look is accented with TUV SUD specialized branding.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>TUV SUD South Asia Pvt. Ltd.</div>
                                    <div className={style.projectLocation}>Noida, UP, India.</div>
                                    <div className={style.projectIndustry}>Testing & Certification</div>
                                    <div className={style.projectCompletion}>2016</div>
                                    <div className={style.projectSize}>7000</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_tuv_01" className={cx(style.image)}>
                                        <Img fluid={data.client_tuv_01.childImageSharp.fluid}
                                            alt="client_tuv_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_tuv_03" className={cx(style.image)}>
                                        <Img fluid={data.client_tuv_03.childImageSharp.fluid}
                                            alt="client_tuv_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_tuv_02" className={cx(style.image)}>
                                        <Img fluid={data.client_tuv_02.childImageSharp.fluid}
                                            alt="client_tuv_02.jpg"
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                        {/* TUV ****************************** */}

                        {/* Hexagon ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Precision detailing
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        Hexagon Metrology (India) Pvt. Ltd. is daughter concern of global Hexagon Group. This division looks after the manufacturing of precision equipment to the industry. From hand tools to automated solutions, coordinate measuring machines (CMMs) to portable measuring arms, laser trackers and optical scanners, Hexagon Manufacturing Intelligence provides industrial metrology systems for when dimensional measurement matters.
                                    </p>
                                    <p>
                                        The newly built office cum Lab for Hexagon is an important one capturing customers in north India region. Spread over a floor plate of 8500 sqft the office consists of an equipment Lab centre close to reception area, a 20 seater board room, MD cabins, manager’s cabin, executive cubicles, equipment store and a small cafeteria.
                                        The project was a low budget project keeping the required aesthetics and precision of the detailing. Overall, it was a satisfactory completion project which came at par with clients expectations. Client has handed over an appreciation letter soon after successful completion of the project.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>Hexagon Metrology (India) Pvt. Ltd</div>
                                    <div className={style.projectLocation}>Noida, UP, India.</div>
                                    <div className={style.projectIndustry}>Precision Manufacturing</div>
                                    <div className={style.projectCompletion}>2012</div>
                                    <div className={style.projectSize}>8500</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_hexgon_metrology_01" className={cx(style.image)}>
                                        <Img fluid={data.client_hexgon_metrology_01.childImageSharp.fluid}
                                            alt="client_hexgon_metrology_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_hexgon_metrology_02" className={cx(style.image)}>
                                        <Img fluid={data.client_hexgon_metrology_02.childImageSharp.fluid}
                                            alt="client_hexgon_metrology_02.jpg"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Hexagon ****************************** */}



                    </div>

                    <div id="team" >
                        < Team />
                    </div>

                    <div id="clients" >
                        < Clients />
                    </div>



                    <div id="location" >
                        < Location />
                    </div>

                    {/* <div id="contact" > */}
                    < Footer />
                    {/* </div> */}
                    <div id="contact" >
                        < Contact />
                    </div>

                    < Identity />

                    < Anchors />

                </main>
            </div>

        </>
    )
}
